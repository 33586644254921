// apiConstants.js

export const API_BASE_URL = 'https://api.squaremedtech.com/api/'; // Replace with your API base URL
export const API_BASE_URL_NEW = 'https://socialpost.drsiamalmobarakcardio.com/api/'; // Replace with your API base URL

export const API_ENDPOINTS = {
  GET_DATA: '/api/blogs',
  // Add more API endpoints here
};


export const API_ENDPOINTS_galleries = {
  GET_DATA: '/api/galleries',
  // Add more API endpoints here
};

export const video =
  'https://api.squaremedtech.com/api/videos'

export const API_ENDPOINTS_gallery_post = {
  POST_DATA: '/api/galleries/update', // Updated endpoint to POST dat
};

///gallery

export const updatedgalleryitem = (item) => `${API_BASE_URL}galleries/update?id=${item.id}&title=${item.title}&description=${item.description}&${item.image}`;
export const postgalleryitem = () => ` ${API_BASE_URL}galleries/create`;
export const getgalleryitem = () => ` ${API_BASE_URL}galleries`;
export const deletegalleryitem = () => ` ${API_BASE_URL}galleries/delete`;

///blog

export const postblogelement = () => `  ${API_BASE_URL}blogs/create`;
export const getblogelement = () => `  ${API_BASE_URL}blogs`;
export const updateblogelement = () => `  ${API_BASE_URL}blogs/update?id`;
export const deleteblogelement = () => `  ${API_BASE_URL}blogs/delete`;

///video

// export const postvideoelement = () => `  ${API_BASE_URL}videos/create`
export const postvideoelement = () => `  ${API_BASE_URL}videos/create`;
export const updatevideoelement = () => `  ${API_BASE_URL}videos/update`;
export const deletevideoelement = () => `  ${API_BASE_URL}videos/delete`;
export const getvideoelement = () => `${video}`;

export const getClinicalLearningelement = () => `  ${API_BASE_URL}easy/clinical/learning`;
export const updateClinicalLearningelement = () => `  ${API_BASE_URL}easy/clinical/learning/update?id`;
export const postClinicalLearningelement = () => `  ${API_BASE_URL}easy/clinical/learning/create`;
export const deleteClinicalLearningelement = () => `  ${API_BASE_URL}easy/clinical/learning/delete`;
export const contactreport = () => `  ${API_BASE_URL}contact-list`;


export const researchpublication = () => `  ${API_BASE_URL_NEW}publication-part-create`;



