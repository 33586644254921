import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function BasicTable({ rows, columns, ActionCell }) {
    return (
        <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
            <Table 
                sx={{ minWidth: 650, tableLayout: 'fixed' }} 
                aria-label="simple table"
            >
                <TableHead>
                    <TableRow>
                        {columns.map((column, index) => (
                            <TableCell 
                                key={index} 
                                sx={{ width: column.width || 'auto', minWidth: 150 }}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, rowIndex) => (
                        <TableRow
                            key={rowIndex}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            {columns.map((column, colIndex) => (
                                <TableCell key={colIndex} component="th" scope="row">
                                    {row[column.id] || ActionCell({ row })}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
